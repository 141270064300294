import {
  cardTitle,
  container,
  description,
  hexToRgb,
  section,
  textCenter,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const chilimaniaStageStyle = (theme) => ({
  lineupCarousel: {
    boxShadow: "0px 0px 250px rgb(226 161 117)",
    height: "350px",
    [theme.breakpoints.down("lg")]: {
      height: "350px",
      width: "85% !important",
      margin: "0 auto",
    },
    [theme.breakpoints.down("md")]: {
      height: "300px",
      width: "85% !important",
      margin: "0 auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60% !important",
      height: "225px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "125px",
      width: "45% !important",
    },
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "2",
  },
  section: {
    ...section,
    position: "relative",
    padding: "70px 0",
    backgroundSize: "initial",
    margin: "0 -85px",
    backgroundPositionX: "12.5%",
    "& .slick-dots": {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "0",
        bottom: "0",
      },
    },
    "& .slick-caption": {
      position: "relative !important",
      marginTop: "55px",
      left: "0 !important",
      right: "0 !important",
      bottom: "0 !important",
      fontSize: "1.75rem",
      lineHeight: "2.5rem",
      padding: "0 !important",
      [theme.breakpoints.down("md")]: {
        marginTop: "37.5px",
        fontSize: "1.5rem",
        lineHeight: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "32.5px",
        fontSize: "1.25rem",
        lineHeight: "1.5rem",
        display: "block !important",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "-7.5px",
        display: "block",
        fontSize: ".7rem",
        lineHeight: "1rem",
      },
    },
    "& .slick-image": {
      height: "350px",
      [theme.breakpoints.down("lg")]: {
        height: "350px",
      },
      [theme.breakpoints.down("md")]: {
        height: "300px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "225px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "125px",
      },
    },
  },
  centerItems: {
    height: "100%",
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    position: "absolute",
    width: "700px",
    top: "30%",
    left: "50%",
    transform: "translateX(-50%)",
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor + "  !important",
  },
  description: {
    ...description,
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
  },
  textLeft: {
    textAlign: "left",
  },
  lineupHeader: {
    ...textCenter,
    width: "100%",
    position: "absolute",
    margin: "40.5px 0px",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      margin: "32px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "18px 0px",
      fontSize: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "7.5px 0px",
      fontSize: "2rem",
    },
  },
  lineupDescription: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".65rem",
    },
    "& a": {
      color: "#eee",
      "&:hover": {
        color: "#2196f3",
      },
    },
  },
  lineupButton: {
    fontSize: "1rem",
    width: "250px",
    height: "75px",
    position: "absolute",
    top: "75%",
    left: "50%",
    transform: "translateX(-50%)",
    border: "2px solid",
    borderRadius: ".25rem",
  },
  lineupStartTime: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      marginRight: "5px",
    },
  },
  stageImage: {
    width: "100%",
    height: "intrinsic",
    background: "rgba(255, 255, 255, 0.6)",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 75px",
    },
  },
  bandName: {
    fontSize: "2.75rem",
    fontWeight: "500",
    marginBottom: "0",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".9rem",
    },
  },
  lineupSponsorWrapper: {
    "& .gatsby-image-wrapper": {
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      height: "70px",
      marginTop: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "55px",
      marginTop: "157.5px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      marginTop: "85px",
    },
    height: "80px",
    marginTop: "257.5px",
  },
})

export default chilimaniaStageStyle
